.highlight {
    background-color: rgb(145, 255, 0, .2);
}

.padding-right {
    padding-right: 5px;
}

.title-bar-title {
    color: #404040;
    flex-grow: 4;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0;
    text-align: left;
}

.right-side-buttons {
    display: flex;
    justify-content: right;
    padding-right: 24px;
    padding-bottom: 20px;
}

.form-row {
  margin: 16px 0;
}
.form-row .form-label {
  font-size: 75%;
  color: #737373;
}
.form-row input {
  min-width: 50px;
}

.react-datepicker__calendar-icon {
  height: 100% !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.react-datepicker__view-calendar-icon input {
  padding-left: 40px;
}
