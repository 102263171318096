.Header {
  display: flex;
  gap: 30px;
  padding: 10px 30px 10px 30px;
  height: 55.76px;
  background: #548C3F;
}

.Header-title {
  flex-grow: 4;
  align-self: center;
  font-size: 20px;
  font-weight: 700;
  color: white;
}

.Header-title a {
  text-decoration: inherit;
  color: inherit;
  display: inline;
  padding: 0;
  margin: 0;
}

.Header-logo {
  max-width: 179.67px;
  max-height: 55.76px;
}

.Header-icon {
  align-self: center;
}

.Header-auth {
  align-self: flex-end;
  color: white;
}

.Header-auth button {
  background: none!important;
  border: none;
  padding: 0!important;
  font: inherit;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}
