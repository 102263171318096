:root {
    --toast-speed: 250ms;
    --toast-list-scrollbar-width: 0.35em;
    --toast-list-width: 400px;
    --toast-list-padding: 1em;
}

.toast-list {
    position: fixed;
    padding: var(--toast-list-padding);
    width: 100%;
    max-width: var(--toast-list-width);
    max-height: 100vh;
    overflow: hidden auto;
}

.toast-list--Top-left {
    top: 0;
    left: 0;
}

.toast-list--Top-right {
    top: 0;
    right: 0;
}

.toast-list--Bottom-left {
    bottom: 0;
    left: 0;
}

.toast-list--Bottom-right {
    bottom: 0;
    right: 0;
}

.toast-list--Top-left .toast,
.toast-list--Bottom-left .toast {
    animation: toast-in-left var(--toast-speed);
}

.toast-list--Top-right .toast,
.toast-list--Bottom-right .toast {
    animation: toast-in-right var(--toast-speed);
}

.toast-list .toast {
    transition: transform var(--toast-speed), opacity var(--toast-speed),
    box-shadow var(--toast-speed) ease-in-out;
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

.toast-list::-webkit-scrollbar {
    width: var(--toast-list-scrollbar-width);
}

.toast-list::-webkit-scrollbar-track {
    background-color: #FFFFFF;
    border-radius: var(--toast-list-scrollbar-width);
}

.toast-list::-webkit-scrollbar-thumb {
    border-radius: var(--toast-list-scrollbar-width);
    background-color: #FFFFFF;
}

.toast-list::-webkit-scrollbar-thumb:window-inactive {
    background-color: #FFFFFF;
}