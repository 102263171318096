.formContainer {
  padding: 0 2vw;
}

.formSection {
  display: flex;
  border-bottom: 2px solid #c4c4c4;
  padding: 20px 0px;
  flex-wrap: wrap;
}

.projectTaskSection {
  flex-direction: column;
  gap: 28px;
  padding: 4vh 0;
}

.formRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 35px;
}

.footerSection {
  display: flex;
  flex-direction: row;
  gap: 92px;
  justify-content: flex-end;
  padding: 20px 50px;
  color: #404040;
}