.button {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    gap: 5px;
    height: 42px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    cursor: pointer;
}

.button:hover {
    box-shadow: 2px 2px 4px 1px #0000001A;
}

.button:active {
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
}

.tertiary-btn {
    border: none;
    color: #6991CB;
    background: none;
}

.secondary-btn {
    border-radius: 4px;
    border: 2px solid #6991CB;
    color: #6991CB;
    background: white;
}

.primary-btn {
    border-radius: 4px;
    border: 2px solid #6991CB;
    color: #FFFFFF;
    background: #6991CB;
}

.disabled-btn {
    color: #6991CB;
    background: none;
    opacity: 50%;
    pointer-events: none;
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
}

.disabled-button-wrapper {
    cursor: not-allowed;
}