.subtitle-row {
  display: flex;
  justify-content: space-between;
  align-items: left;
  margin: 0vh 0 4vh 2vw;
}

.subtitle-container {
  margin-right: auto;
  flex-direction: column;
}

.subtitle-header {
  color: #737373;
  font-size: 16px;
}

.subtitle-item {
    font-size: 16px;
    color: #404040;
    display: flex;
    align-items: center;
}
