.App-bar {
    background: white;
    height: 100px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0 2px 4px 0 #0000001A;
}

.navigation {
    display: flex;
    gap: 5px;
    margin-top: 20px;
}

.navigation-breadcrumb {
    color: #6991CB;
    font-weight: 700;
    cursor: pointer;
}

#client-title {
    display: inherit;
    flex-grow: 2;
    justify-content: right;
    color: #737373;
}

.title-bar {
    display: flex;
    margin: 30px 2vw 0 2vw;
    gap: 24px;
}

.title-bar-title {
    color: #404040;
    flex-grow: 4;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0;
    text-align: left;
}

.right-side-buttons {
    display: flex;
    justify-content: flex-end;
    padding-right: 24px;
    padding-bottom: 0;
}

.header-row {
    display:flex;
    align-items: center;
    width: 100%;
    padding-bottom: 25px;
}

.error-title {
    font-size: 24px;
    font-weight: 400;
}

.header-button {
    margin-left: auto;
}

.modal-text {
    padding-bottom: 40px;
    padding-right: 24px;
}

.modal-container {
    display:flex;
    flex-direction:column;
    flex:1;
    height: 100%;
}

.bottom-row {
    margin-top: auto;
}