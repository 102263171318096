#dropdownContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

#dropdownContainer label {
    color: #404040;
}

select {
    height: 44px;
    padding-left: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background: url('../../../public/images/downarrow.png') no-repeat right #fff;
    background-position-x: 96%;
    border: 1px solid #919191;
}

select:disabled {
    cursor: not-allowed;
}