#textfieldContainer {
  width: 87px;
}

#textfieldContainer input {
  padding-left: 0px;
  text-align: center;
}

#removeIcon {
  padding-top: 25px;
}
