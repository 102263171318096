table {
    width: 98%;
    border-collapse: collapse;
    margin-left: 1vw;
    text-indent: 1vw;
    border-top: 2px solid #C4C4C4;
}

th {
    border-bottom: 2px solid #C4C4C4;
    font-weight: 500;
    text-align: left;
    padding: 1.5vh 0vw;
    width: 0%;
}

tr {
    height: 7vh;
}

.table-title {
    color: #404040;
    flex-grow: 4;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 4vh;
    margin: 4vh 0 0 1vw;
}