:root {
  --secondary-hue: 25;
  --input-radius: 0.25em;
  --input-margin: 0.5em;
  --input-padding: 1em;
  --input-border-width: 1px;
  --paragraph-margin: 1em;
  --text-leading: 1.5;

  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F2F2F2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error {
  border: 2px solid red;
}

.errorMessage {
  font-size: 12px;
  font-weight: 400;
  color: #E03157;
}

.loader {
  padding: 50px;
  text-align: center;

  position: relative;
  left: 0px;
  right: 0px;
  top: 0px;
}
