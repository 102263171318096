.dashboardRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    padding: 3vh 0;
    border-bottom: 2px solid #C4C4C4;
}

.dashboardItemContainer {
    margin-right: auto;
    flex-direction: column;
}

.dashboardItemHeader {
    color: #737373;
    font-size: 16px;
}

.dashboardItemAccrual {
    font-size: 16px;
    color: #404040;
    display: flex;
    align-items: center;
}

.dashboardItemConsultant {
    font-size: 18px;
    color: #404040;
    display: flex;
    align-items: center;
}
