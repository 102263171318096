:root {
    --toast-success-hue: 150;
    --toast-error-hue: 20;
    --toast-warning-hue: 205;
    --toast-padding: 1em;
    --toast-margin: 0.5em;
    --toast-bg-color-fallback: hsl(var(--secondary-hue) 15% 100%);
    --toast-text-color-fallback: hsl(var(--secondary-hue) 10% 25%);
    --toast-opacity: 0.99;
    --toast-content-gap: 0.5em;
}

.toast {
    background-color: var(--toast-bg-color-fallback);
    padding: var(--toast-padding);
    box-shadow: hsl(var(--secondary-hue) 15% 15% / 15%) 1px 2px 4px 3px,
    hsl(var(--secondary-hue) 10% 10% / 5%) 0px 1px 2px 0px;
    border-radius: 0.05em;
    position: relative;
    color: var(--toast-text-color-fallback);
    opacity: var(--toast-opacity);
}

.toast:hover {
    opacity: 1;
    box-shadow: hsl(var(--secondary-hue) 10% 10% / 30%) 0px 1px 3px 0px,
    hsl(var(--secondary-hue) 10% 10% / 15%) 0px 1px 2px 0px;
}

.toast + .toast {
    margin-top: var(--toast-margin);
}

.toast-message {
    display: flex;
    gap: var(--toast-content-gap);
    align-items: flex-start;
}

.toast-close-btn {
    position: absolute;
    top: 0.5em;
    right: 1.25em;
    padding: 0;
    line-height: 1;
    height: 1em;
    width: 1em;
    scale: 50%;
    background: none;
    border: none;
    cursor: pointer;
    color: inherit;
    opacity: 1;
}

.toast--success {
    color: hsl(var(--toast-success-hue) 97% 3%);
    background-color: hsl(var(--toast-success-hue) 98% 99%);
}
.toast-header {
    display: flex;
    justify-content: left;
    align-items: center;
}

.toast-title {
    text-align: center;
}

.icon--thumb {
    scale: 65%
}

.toast--success .icon--thumb {
    color: hsl(var(--toast-success-hue) 90% 40%);
}

.toast--error {
    color: hsl(var(--toast-error-hue) 97% 3%);
    background-color: hsl(var(--toast-error-hue) 98% 99%);
}

.toast--error .icon--thumb {
    color: hsl(var(--toast-error-hue) 90% 40%);
}
