:root {
  --app-width: 100%;
  /*--app-spacing: 2em;*/
  --app-background: #fff;
  --app-title-size: 1.5em;
  --app-border-width: 4px;
  --app-border-radius: 1em;
  --app-group-gap: 1em;
  --app-group-gap-lg: 1.5em;
}

.app {
  font-family: sans-serif;
}

.container {
  background: white;
  margin: 4vh 2vw;
  border: 2px solid #0000001A ;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #0000001A;
  min-height: 80vh;
}