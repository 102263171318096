#textfieldContainer {
    display: flex;
    flex-direction: column;
    max-width: 555px;
}

#textfieldContainer label {
    color: #404040;
}

input {
    height: 40px;
    padding-left: 8px;
    background: white;
    border: 1px solid #919191;
}

input:disabled {
    cursor: not-allowed;
}

#textfieldContainer input[type="text"]:disabled {
    color: #40404080;
    font-size: 16px;
}